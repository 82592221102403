import React, { useState, useEffect } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Stack,
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  Alert,
  Typography,
} from '@mui/material';
import JsFileDownloader from 'js-file-download';
import { getDate, formatDate } from '../../utils/datetime.util';
import Datepicker from '../form-elements/datepicker.component';
import NoData from '../table-elements/no-data.component';
import Loading from '../table-elements/loading.component';
import {
  getDailyWatchedSecondsAllData,
  getDailyWatchedSecondsAggregateData,
} from '../../services/admin-reports.service';

const DailyWatchedSecondsCSV = () => {
  const [tableRows, setTableRows] = useState({
    startDate: getDate(-15),
    endDate: getDate(0),
    loading: true,
    rows: [],
  });
  const [filters, setFilters] = useState({
    channel: null,
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
    loading: false,
    errorMessage: '',
  });

  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };

  const downloadReportsData = () => {
    setFilters({
      ...filters,
      loading: true,
      errorMessage: '',
    });

    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    const downloadFileName = `all_analytics_${startDate}_${endDate}`;
    const payload = {
      startDate,
      endDate,
    };
    getDailyWatchedSecondsAllData(payload)
      .then((res) => {
        // eslint-disable-next-line no-unused-vars
        const download = new JsFileDownloader(res, `${downloadFileName}.csv`);
        setFilters({
          ...filters,
          loading: false,
          errorMessage: '',
        });
      })
      .catch((e) => {
        // to do
        setFilters({
          ...filters,
          loading: false,
          errorMessage:
            e?.response?.data?.message ||
            'Something went wrong, please try again.',
        });
      });
  };

  useEffect(() => {
    const startDate = formatDate(tableRows.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(tableRows.endDate, 'YYYY-MM-DD');
    const payload = {
      startDate,
      endDate,
    };
    getDailyWatchedSecondsAggregateData(payload)
      .then((res) => {
        setTableRows({
          ...tableRows,
          loading: false,
          rows: res.data,
        });
      })
      .catch(() => {
        setTableRows({
          ...tableRows,
          loading: false,
        });
      });
  }, []);

  return (
    <Card>
      <CardHeader
        title="Daily Watch Seconds (Including Gideo)"
        subheader="Please keep the date duration up to one month only"
      />

      <Box sx={{ p: 3 }} dir="ltr">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="left">
              <Datepicker
                id="wssc_start_date"
                name="wssc_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ width: 280 }}
              />
              &nbsp;&nbsp;
              <Datepicker
                id="wssc_end_date"
                name="wssc_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 280 }}
              />
            </Stack>
          </Grid>
          <Grid item sx={{ justifyContent: 'right' }}>
            <Button
              variant="contained"
              onClick={downloadReportsData}
              disabled={filters.loading}
            >
              Export CSV
            </Button>
          </Grid>
        </Grid>
      </Box>

      {filters.errorMessage !== '' && (
        <Box sx={{ pl: 3, pr: 3, pb: 3 }}>
          <Alert severity="error">{filters.errorMessage}</Alert>
        </Box>
      )}

      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Last 2 Weeks Aggregate Data
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" width="40%">
                  Date
                </TableCell>
                <TableCell align="right" width="30%">
                  Hexabrain
                </TableCell>
                <TableCell align="right" width="30%">
                  Gideo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!tableRows.loading && tableRows.rows.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <NoData />
                  </TableCell>
                </TableRow>
              )}

              {tableRows.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}

              {tableRows.rows.map((r, index) => (
                <TableRow key={`row-date-${index}`}>
                  <TableCell>{r.date || ''}</TableCell>
                  <TableCell align="right">{r.hexabrain || 0}</TableCell>
                  <TableCell align="right">{r.gideo || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default DailyWatchedSecondsCSV;
